import {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { CollectionFilter, getCollectionFilters } from "../services/atomic";
import Filters from "./Filters";
import { useRecoilState } from 'recoil';
import { selectedFiltersState } from '../atoms/atoms';

export enum VisibilityFilters {
    UNIQUES = 'Uniques (name)',
    DUPLICATES = 'Duplicates (name)'
}

const FiltersList = ({ collection_name, schema_name, template_id, setTooltipAttributes }: { collection_name: string, schema_name: string, template_id: number, setTooltipAttributes: (value: Set<string>) => void }) => {

    const { chain } = useParams();
    const [ filters, setFilters ] = useState<CollectionFilter[]>([]);
    const [ searchParams ] = useSearchParams();
    const [ selectedFilters, setSelectedFilters ] = useRecoilState(selectedFiltersState);

    

    if ( !chain ) throw new Error("chain is required");

    useEffect(() => {
        const preselectedFilters: {key: string, value: string}[] | undefined = searchParams.get('filters')
            ?.split(',')
            ?.map(filter => {
                return {
                    key: filter.split(':')[0],
                    value: filter.split(':')[1],
                }
            })

        if (!preselectedFilters?.length) {
            return;
        }

        const newSelectedFilters = new Set<string>(Array.from(selectedFilters));
        preselectedFilters.forEach((preselectedFilters) => {
            const matchedFilter = filters.find((filter) => {
                return filter.attribute_name === preselectedFilters.key
            });
            if (matchedFilter) {
                const key = [template_id, preselectedFilters.key, preselectedFilters.value].join(',');
                newSelectedFilters.add(key);
            }
        })

        if (newSelectedFilters.size) {
            setSelectedFilters( new Set(Array.from(newSelectedFilters)) );
        }
    }, [filters])

    useEffect(() => {
        onLoad();
    },[]);

    const onLoad = async () => {
        const response = await getCollectionFilters(collection_name, chain);
        const data = response.filter( value => value.schema_name === schema_name);
        console.log("FiltersList::onLoad", { template_id, data, length: data.length })
        setTooltipAttributes( new Set(data.map(d => d.attribute_name!)) );
        setFilters([
            {
                filter_name: 'Visibility',
                attribute_name: 'is_custom',
                position: 0,
                possible_values: [
                    { value: VisibilityFilters.UNIQUES, type: 'string' },
                    { value: VisibilityFilters.DUPLICATES, type: 'string' }
                ],
            },
            ...data
        ]);
    }

    const filtersList = filters.map(( filter, index: number ) => {
        return (
            <Grid item xs={12} key={ index }>
                <Filters template_id={ template_id } filter={ filter } />
            </Grid>
        )
    })

    return (
        <Grid container style={{ padding:"16px", textAlign:"left" }}>
            { filtersList }
        </Grid>
    )

}

export default FiltersList;