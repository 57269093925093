import { BottomNavigation, Grid } from '@material-ui/core';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { selectedAssetsState, protocolState, trxIdState, ownerState } from '../atoms/atoms';
import { useParams } from "react-router-dom";
import BlendButton from "./BlendButton";
import * as actions from '../utils/actions';
import EOSWallet from "eosio-wallets";
import { useSnackbar } from 'notistack';
import * as ipfs from '../utils/ipfs';
import { useEffect, useState } from 'react';

const BlendBar = ({ start_time, end_time }: { start_time: string, end_time: string }) => {
    const { chain, contract, collection_name, template_id } = useParams();
    if ( !chain ) throw new Error("[chain] is required");
    if ( !contract ) throw new Error("[contract] is required");
    if ( !collection_name ) throw new Error("[collection_name] is required");
    if ( !template_id ) throw new Error("[template_id] is required");

    const protocol = useRecoilValue( protocolState );
    const selectedAssets = useRecoilValue( selectedAssetsState );
    const owner = useRecoilValue( ownerState );
    const setTrxId = useSetRecoilState( trxIdState );
    const { enqueueSnackbar } = useSnackbar();
    const [isExpired, setIsExpired] = useState<boolean>(false);

    useEffect(() => {
        if ( end_time ) setIsExpired(+new Date(end_time) < +new Date());
    }, [end_time])

    const onClick = async () => {
        // setTrxId( () => "44db907df44185133bfe1a248646cf3e70a2a8ca8e8f498ad954c0b3548c93b8" )
        // return;

        const asset_ids = [...selectedAssets.keys() ];
        console.log("BlendBar::onClick:", {collection_name, template_id, selectedAssets, asset_ids});
        const action = actions.blend( owner, contract, asset_ids, collection_name, template_id );

        try {
            const trx_id = await EOSWallet.pushTransaction([action], protocol, true );
            setTrxId( () => trx_id );
        } catch (err: any) {
            enqueueSnackbar(`Failed blend: ${err.response?.json?.error?.details?.[0]?.message ?? err.message ?? err}`, { variant: 'error' });
            console.error( err);
        }
    }

    return (
        <BottomNavigation style={{
            background: "linear-gradient(to left, rgb(51, 48, 211), rgba(100, 31, 233, 0.48))",
            position: "fixed",
            bottom: "0%",
            width: "100%",
            zIndex: 1000,
            borderRadius: "25px 25px 0px 0px",
            filter: isExpired ? 'grayscale(1)' : 'grayscale(0)'
        }}>
            <BlendButton start_time={ start_time } disabled={ isExpired } onClick={ onClick }/>
        </BottomNavigation>
        //   <BottomNavigation style={{
        //     background: "linear-gradient(to left, rgb(51, 48, 211), rgba(100, 31, 233, 0.48))",
        //     position: "fixed",
        //     bottom: "0%",
        //     width: "100%",
        //     zIndex: 1000,
        //     borderRadius: "25px 25px 0px 0px",
        // }}>
        //     <Grid container style={{ display:"flex", flexDirection:"row" }}>
        //         <Grid item xs={12} sm={6} style={{ display:"flex", margin:"auto" }}>
        //         <img
        //             style={{ height:"50px" }}
        //             onError={(event: any) => event.target.src = ipfs.get_fallback_url("QmRD7oaHcvwetnY9bbshYnj6LccnK46CP2bD55T8b11yzU") }
        //             src={ ipfs.get_url("QmRD7oaHcvwetnY9bbshYnj6LccnK46CP2bD55T8b11yzU") }
        //         />
        //         </Grid>
        //     <Grid item xs={12} sm={6} style={{ display:"flex" }}>
        //         <BlendButton start_time={ start_time }onClick={ onClick }/>
        //     </Grid>
        //     </Grid>

        // </BottomNavigation>
    )
}

export default BlendBar;
