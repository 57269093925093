import {useState, useEffect} from 'react';
import { Grid, Card, Select, MenuItem } from '@material-ui/core';
import { AtomicAsset, ImmutableSerializedData, NFT } from "../types"
import InventoryList from "./InventoryList";
import RecipeBadge from "./RecipeBadge";
import { parseRecipes } from "../utils/recipes"
import { useRecoilState, useRecoilValue } from 'recoil';
import { isRefreshInventoryState, ownerState } from '../atoms/atoms';
import * as services from '../services';
import { useParams } from 'react-router-dom';

interface DynamicAsset { 
  key: string;
  quantity: number;
  template_id: number;
  blendQuantity: number;
  collection_name: string;
  schema_name: string;
}

const displayInvetory = (dynamicList: DynamicAsset[], selectedDynamicIngredient: number) => {
  if ( !dynamicList?.length ) throw new Error('[dynamic] is empty');
  return <InventoryList
          optional={ true }
          template_id={ dynamicList[selectedDynamicIngredient].template_id }
          quantity={ dynamicList[selectedDynamicIngredient].blendQuantity }
          collection_name={ dynamicList[selectedDynamicIngredient].collection_name }
          schema_name={ dynamicList[selectedDynamicIngredient].schema_name }
        />
}

type MultiRecipeBlendParam = {
  recipes: NFT[][],
  immutable_serialized_data: {[template_id: number]: ImmutableSerializedData}
}

const MultiRecipeBlend = ({ recipes, immutable_serialized_data} : MultiRecipeBlendParam ) => {
  const [ fixed, dynamic ] = parseRecipes(recipes);
  const [ selectedDynamicIngredient, setSelectedDynamicIngredient ] = useState<number>(0);
  const [ isRefreshInventory, setIsRefreshInventory ] = useRecoilState(isRefreshInventoryState);
  const { chain, contract } = useParams();
  const [ dynamicAssets, setDynamicAssets ] = useState<AtomicAsset[] | null>(null)
  const [ dynamicList, setDynamicList] = useState<DynamicAsset[]>([]);

  const owner = useRecoilValue( ownerState );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDynamicIngredient(event.target.value as number)
  }

  useEffect(() => {
    if (!dynamicAssets) return;
    const dynamicListSorted = Array.from(dynamic.entries()).map(([key, quantity]) => {
      const template_id = Number(key.split(",")[1]);
      const collection_name = String(key.split(",")[0]);
      const schema_name = String(key.split(",")[2]);
      return {
        key,
        quantity: dynamicAssets?.filter((asset) => +asset.template.template_id === +template_id).length || 0,
        template_id,
        blendQuantity: quantity,
        collection_name,
        schema_name
      }
    });
    dynamicListSorted.sort((a,b) => b.quantity - a.quantity);
    setDynamicList(dynamicListSorted)
  }, [dynamicAssets])

  useEffect(() => {
    const templateIdList = Array.from(dynamic.entries()).map(([key, quantity]) => {
      const template_id = Number(key.split(",")[1]);
      return template_id
    })
    if (!chain) return;
    if (!owner) {
      setDynamicAssets([]);
      return;
    }

    const loadData = async () => {
      const assets = await services.atomic.getAssets( chain, owner, { template_id: templateIdList.join(',') } );
      setDynamicAssets(assets);
    }

    loadData();

  }, [recipes, owner])

  useEffect(() => {
    setIsRefreshInventory(true);
  }, [selectedDynamicIngredient])

  return (
    <Grid container style={{ display:"flex", justifyContent:"center" }}>
      { Array.from(fixed.entries()).map(([key, quantity]) => {
          const collection_name = key.split(",")[0];
          const schema_name = key.split(",")[2]; // TODO: test if multiple blends works correctly with filters
          const template_id = Number(key.split(",")[1]);
          return (
            <Grid key={template_id} item xs={12} sm={6} md={6} lg={4} xl={3} style={{ padding: "6px", display: "flex", flexDirection:"row", justifyContent:"center" }}>
              <Card style={{width:"100%", borderRadius:"24px", padding:"8px 0px 16px 0px"}}>
                <Grid container style={{ display:"flex", justifyContent:"center" }}>
                  <RecipeBadge template_id={ template_id } quantity={ quantity } immutable_serialized_data={ immutable_serialized_data } />
                  <InventoryList template_id={ template_id } quantity={ quantity } collection_name={ collection_name } schema_name={ schema_name } />
                </Grid>
              </Card>
            </Grid>
          )
        })
      }
      {
        dynamicList?.length ?
         <Grid item xs={12} sm={6} md={6} lg={4} xl={3} style={{ padding: "6px", display: "flex", flexDirection:"row", justifyContent:"center" }}>
          <Card style={{width:"100%", borderRadius:"24px", padding:"8px 0px 16px 0px"}}>
            <Grid container style={{ display:"flex", justifyContent:"center" }}>
              <Select
                labelId="select-label"
                id="simple-select"
                label="Pick an ingredient"
                value={selectedDynamicIngredient}
                onChange={handleChange}
                style={{ width:"inherit", margin: "0px 8px"}}
              >
                <MenuItem disabled value="">
                  <em>Please select your ingredient</em>
                </MenuItem>
                {
                  dynamicList.map((item, index) => {
                    return (
                      <MenuItem key={'dynamicItem' + index} value={index} style={{ padding:"0px", maxWidth:"500px", filter: item.quantity ? 'grayscale(0)' : 'grayscale(1)', opacity: item.quantity ? '1' : '0.7' }}>
                        <RecipeBadge optional={ true } template_id={ item.template_id } quantity={ `${item.quantity} of ${item.blendQuantity}` } immutable_serialized_data={ immutable_serialized_data } />
                      </MenuItem>
                    )
                  })
                }
              </Select>
              {
                owner && displayInvetory(dynamicList, selectedDynamicIngredient)
              }
            </Grid>
          </Card>
        </Grid>
        : ""
      }
    </Grid>
  )
};

export default MultiRecipeBlend;

