import anchor from "./anchor.svg";
import scatter from "./scatter.svg";
import tokenpocket from "./tokenpocket.png";
import wombat from "./wombat.png";
import imtoken from "./imtoken.png";
import start from "./start.png";
import leaf from "./leaf.png";
import metahub from "./metahub.png";

export const wallets = [
  {
    name: "Anchor",
    img: anchor,
    protocol: "anchor",
  },
  {
    name: "wombat",
    img: wombat,
    protocol: "scatter",
  },
  {
    name: "TP",
    img: tokenpocket,
    protocol: "scatter",
  },
  {
    name: "Scatter",
    img: scatter,
    protocol: "scatter",
  },
  {
    name: "ImToken",
    img: imtoken,
    protocol: "scatter",
  },
  {
    name: "Start",
    img: start,
    protocol: "scatter",
  },
  {
    name: "Leaf",
    img: leaf,
    protocol: "scatter",
  },
  {
    name: "Metahub",
    img: metahub,
    protocol: "scatter",
  },
];