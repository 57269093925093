import { Action } from 'eosjs/dist/eosjs-serialize';

export function blend(from: string, contract: string, asset_ids: number[], collection_name: string, template_id: number | string ): Action {
    if ( !collection_name ) throw new Error("[collection_name] is required");
    if ( !template_id ) throw new Error("[template_id] is required");

    const memo = `${collection_name}:${template_id}`;
    return atomicTransfer(from, contract, asset_ids, memo );
}

export function atomicTransfer(from: string, to: string, asset_ids: number[], memo: string): Action {
    if ( !from ) throw new Error("[from] is required");
    if ( !to ) throw new Error("[to] is required");
    if ( !asset_ids.length ) throw new Error("[asset_ids] is empty");

    return {
        account: 'atomicassets',
        name: 'transfer',
        authorization: [ { actor: from, permission: "active" }],
        data: {
            from,
            to,
            asset_ids,
            memo
        },
    }
}