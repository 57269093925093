import { useEffect } from "react"
import { Typography } from '@material-ui/core';
import Filter from "./Filter";
import { CollectionFilter } from "../services/atomic";

interface FiltersProps {
    filter: CollectionFilter;
    template_id: number;
}

const Filters = ({ filter, template_id }: FiltersProps) => {
    const { possible_values, filter_name, attribute_name, schema_name } = filter;

    useEffect( () => {
        console.log("Filters::onLoad:", { filter, possible_values, attribute_name, filter_name, schema_name });
    }, []);

    const possibleValuesList = possible_values.map( ({ value }, index ) => {
        return <Filter key={ index } attribute_name={ attribute_name! } template_id={ template_id } schema_name={ schema_name! } value={ value } />
    });

    return (
        <div>
            <Typography variant="h5" style={{ fontFamily:"'proxima-soft', sans-serif" }}>{ filter_name }</Typography>
            { possibleValuesList }
        </div>
    )
}

export default Filters;