import { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import {
  ownerState,
  protocolState,
  balanceState,
} from "../atoms/atoms";
import { AccountBalanceWallet } from "@material-ui/icons";
import EOS from '../assets/chains/eos.png';
import WAX from '../assets/chains/wax.png';
import WalletDialog from "./WalletDialog";
import * as services from "../services";
import EOSWallet from "eosio-wallets"
import * as storage from "../utils/storage"
import { EOSIO_RPC, EOSIO_CHAIN_ID, IDENTIFIER, COSIGN_ENDPOINT, COSIGN_REFERRER } from '../utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "proxima-soft",
    margin: "auto",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "25px",
    },
  },

  button: {
    padding: "8px 16px",
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  dialogCloseButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  dialogContent: {
    paddingBottom: "20px",
  },
  largeButton: {
    borderRadius: "20px",
    margin: "auto",
    backgroundColor: "#FFF",
    padding: "7px 15px",
    color: "rgb(2 28 77)",
    "&:hover": {
      backgroundColor: "#ffffff9e",
    },
  },
}));


const ConnectWallet = () => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const { pathname } = useLocation()
  let [ chain ] =  pathname.split("/").slice(1);
  if ( !chain ) chain = "eos";
  const setBalance = useSetRecoilState(balanceState);
  const [ owner, setOwner] = useRecoilState(ownerState);
  const [ protocol, setProtocol ] = useRecoilState(protocolState);

  useEffect(() => {
    onLoad();
  }, []);

  const loadBalance = async () => {
    if ( owner ) {
      const amount = await services.chain.get_currency_balance_amount("eosio.token", owner, chain.toUpperCase(), chain);
      setBalance(() => amount);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
    loadBalance();
  };

  const onLoad = async () => {

    EOSWallet.init({
      rpcEndpoint: EOSIO_RPC,
      chainId: EOSIO_CHAIN_ID,
      appId: IDENTIFIER,
      cosignEndpoint: COSIGN_ENDPOINT,
      cosignReferrer: COSIGN_REFERRER
    })

    const detectProtocol = EOSWallet.getWalletProtocol();
    console.log("ConnectWallet::onLoad", { chain, owner, protocol, detectProtocol });

    // auto-login Scatter protocol detected
    if ( detectProtocol === "scatter" ) {
      const account = await EOSWallet.login("scatter");
      if ( account ) {
        const owner = account.actor ?? "";
        setOwner( owner );
        setProtocol( protocol );
        storage.set("protocol", protocol );
        storage.set("owner", owner );
      }
    }
  };

  const walletIcon = chain === "wax" ? WAX : EOS;

  return (
    <div className={classes.root}>
      {owner ? (
        <Button
          variant="outlined"
          style={{
            borderRadius: "20px",
            margin: "auto",
            textTransform: "none",
            backgroundColor: "#FFF",
            padding: "3px 12px",
            color: "rgb(2 28 77)",
          }}
          startIcon={<img alt={"icon"} src={ walletIcon } height={25} />}
          onClick={ handleClickOpen }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography style={{ margin: "auto 0px", fontSize: "20px", fontWeight: 500 }}>
              { owner }
            </Typography>
          </div>
        </Button>
      ) : (
        <>
          <Button
            variant="outlined"
            className={ classes.largeButton }
            startIcon={ <AccountBalanceWallet /> }
            onClick={ handleClickOpen }
          >
            Connect
          </Button>
        </>
      )}
      <WalletDialog open={ open } setOpen={ setOpen } />
    </div>
  );
};

export default ConnectWallet;
