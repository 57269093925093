export type Chain = "eos" | "wax" | "telos";

export const IDENTIFIER = "blend.gems"
export const ANCHOR_FUEL_REFERRER = IDENTIFIER;
export const ANCHOR_IDENTIFIER = IDENTIFIER;
export const SCATTER_IDENTIFIER = IDENTIFIER;

export const EOSIO_RPCS: { [chain: string]: string } = {
    'eos': 'https://eos.api.eosnation.io',
    'wax': 'https://wax.api.eosnation.io',
    'telos': 'https://telos.api.eosnation.io',
}

export const EOSIO_CHAIN_IDS: { [chain: string]: string } = {
    'eos': 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    'wax': '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    'telos': '4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11',
}

export const EOSIO_SYMBOLS: { [chain: string]: string } = {
    'eos': 'EOS',
    'wax': 'WAX',
    'telos': 'TLOS',
}

export const EOSIO_SYMBOL = EOSIO_SYMBOLS["eos"];
export const EOSIO_RPC = EOSIO_RPCS["eos"];
export const EOSIO_CHAIN_ID = EOSIO_CHAIN_IDS["eos"];

export const HISTORY_RPC_ENDPOINTS: {[key: string]: string} = {
    'eos': 'https://eos.greymass.com',
    'wax': 'https://wax.greymass.com',
    'telos': 'https://telos.greymass.com',
}

export const COSIGN_ENDPOINT = 'https://edge.pomelo.io/api/cosign'
export const COSIGN_REFERRER = 'blend'