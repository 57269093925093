import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AssetCardImage } from "./CardImage";
import { ImmutableSerializedData } from "../types";

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "block",
    position: "relative",
    right: "12px",
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: "15px",
      transform: "scale(1.5) translate(50%, -50%)"
    },
  },
}))

type RecipeBadgeParam = {
    template_id: number | string;
    quantity: number | string;
    immutable_serialized_data: {[template_id: number | string]: ImmutableSerializedData};
    optional?: boolean;
}

const fallback_names: {[key: number]: string} = {
  6659: "Pomelo Rocket",
  6844: "Pomelo Moon",
}

const RecipeBadge = ({template_id, quantity, immutable_serialized_data, optional }: RecipeBadgeParam) => {
    const classes = useStyles();
    const data = immutable_serialized_data[template_id];
    const name = data.name || fallback_names[Number(template_id)];
    const img = data.img || data.backimg || data.cardimg;
    // const color = optional ? "secondary" : "primary";
    let badge = `x${quantity}`
    if ( optional ) badge += "*";

    return (
      <Grid container style={{padding:"0px 0px 0px 16px"}}>
        <Grid item xs={3}>
          <AssetCardImage img={img} name={name} />
        </Grid>
        <Grid item xs={7} style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign:"left", paddingLeft:"8px", whiteSpace:"pre-wrap" }}>
            <Typography variant="h5" component="div" style={{ fontFamily:"'proxima-soft', sans-serif", fontSize: "1.25rem" }}>{name}</Typography>
            <Typography style={{ fontSize: "14px", fontFamily:"'proxima-soft', sans-serif" }}>Template ID: {template_id}</Typography>
        </Grid>
         <Grid item xs={2} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} className={classes.badge}>
            <Typography 
              variant="h5" 
              component="div" 
              style={{
                borderRadius:"25px", 
                backgroundColor:"#4050B5", 
                color:"#fff", 
                padding: "8px", 
                fontFamily:"'proxima-soft', sans-serif",
                textAlign:"center",
                whiteSpace: "pre-wrap",
                fontSize: "14px"
              }}>{badge}</Typography>
        </Grid>
      </Grid>
    )
};

export default RecipeBadge;