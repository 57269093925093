import { useEffect } from 'react';
import './App.css';
import { Route, Routes, Navigate } from "react-router-dom";
import Page404 from './pages/Page404';
import CollectionPage from './pages/CollectionPage';
import BlendPage from './pages/BlendPage';
import RecipePage from './pages/RecipePage';
import Navbar from './pages/Navbar';

// Tasks
// - Pick network
// - Get inventory Depending on the selected network
// - Display available blends
function App() {
  useEffect(() => {
    document.body.style.backgroundColor = "#f2f3fb";
  }, []);

  return <>
    <Navbar />
    <Routes>
      <Route path="/" element={<Navigate replace to={"/eos/blend.gems"} />} />
      <Route path="/eos" element={<Navigate replace to={"/eos/blend.gems"} />} />
      <Route path="/:chain/:contract" element={<CollectionPage />} />
      <Route path="/:chain/:contract/:collection_name" element={<BlendPage />} />
      <Route path="/:chain/:contract/:collection_name/:template_id" element={<RecipePage />} />
      <Route path="/:chain/:contract/:collection_name/:template_id/:recipe_id" element={<RecipePage />} />
      <Route path="*" element={()=> <Page404/>}/>
    </Routes>
  </>
}

export default App;
