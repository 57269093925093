import { ListItem, Avatar, ListItemText, ListItemSecondaryAction, Checkbox, Tooltip, ListItemAvatar, withStyles } from '@material-ui/core';
import { useEffect, useState } from "react"
import { useRecoilState } from 'recoil';
import { selectedFiltersState } from '../atoms/atoms';
import { AtomicAsset } from '../types/atomic';
import * as ipfs from '../utils/ipfs';

function titleCase( str: string ) {
  return str[0].toUpperCase() + str.slice(1)
}

function parse_label( asset: AtomicAsset ) {
  const parts = [`#${asset.template_mint}`]
  // Add custom attributes
  for ( const key of ["rarity", "mana", "fuel", "clarity"]) {
    if ( asset.data[key] ) parts.push( `${titleCase(key)}:${asset.data[key]}` );
  }
  parts.push( asset.data.name );
  return parts.join(" / ");
}

type InventoryAssetProps = {
  selectAsset: ( asset: AtomicAsset, hide?: boolean ) => boolean;
  asset: AtomicAsset;
  optional?: boolean;
  tooltipAttributes: Set<string>;
  selectedAssets: any;
  isAutoSelectEnabled: boolean;
}

const InventoryAsset = ({ asset, optional, selectAsset, selectedAssets, tooltipAttributes, isAutoSelectEnabled } : InventoryAssetProps ) => {
  const [ checked, setChecked ] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useRecoilState(selectedFiltersState);

  const asset_id = Number( asset.asset_id );
  const labelId = `checkbox-list-secondary-label-${asset_id}`;
  const img = asset.data.img || asset.data.backimg || asset.data.cardimg;
  const label = parse_label( asset );

  useEffect(() => {
    setChecked(selectedAssets?.has(+asset.asset_id))
  }, [selectedAssets, isAutoSelectEnabled, selectedFilters])


  const handleClick = () => {
    selectAsset( asset );
  }

  const HtmlTooltipWithStyles = withStyles({
    tooltip: {
      backgroundColor: !!tooltipAttributes.size ? "rgba(97, 97, 97, 0.9);" : "transparent"
    }
  })(Tooltip);

  return (
    <HtmlTooltipWithStyles
      placement="top"
      arrow={!!tooltipAttributes.size}
      interactive={true}
      title={
        <div style={{fontSize: "14px", margin: "0.5rem 0"}}>
          {
            Array.from(tooltipAttributes).map((attribute: string) => {
              return (
                <div key={attribute} style={{margin: "0.5rem"}}>
                  <b>{attribute}</b>: {asset?.immutable_data[attribute] || asset?.mutable_data[attribute]}
                </div>
              )
            })
          }
        </div>
      }
    >
      <ListItem
        button
        onClick={ handleClick }>
        <ListItemAvatar>
          <Avatar
            alt={`Avatar n°${ asset_id }`}
            onError={(event: any) => event.target.src = ipfs.get_fallback_url(img) }
            src={ ipfs.get_url(img, 100) }
            variant="rounded"
          />
        </ListItemAvatar>
        <ListItemText id={labelId} primary={label} />
        <ListItemSecondaryAction style={{ padding:"8px" }}>
          <Checkbox
            edge="end"
            onChange={ handleClick }
            checked={ checked }
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </HtmlTooltipWithStyles>
  )
}
export default InventoryAsset;