import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import AtomicHub from '../assets/atomichub.svg';
import Close from '@material-ui/icons/Close';
import { Typography, DialogContent, Link, DialogActions, Button } from '@material-ui/core';
import { Dialog} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from "react-router-dom";
import { ownerState, trxIdState, isRefreshInventoryState } from '../atoms/atoms';
import { CardImage } from "./CardImage"
import { Loading } from "./Loading"
import { getBlendlog, Logmint } from "../services/gems"
import { timeout } from "../utils/utils"
import { Transition } from "./Transition";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
        padding:"16px",
        borderRadius: "25px",
    },
  },
}))

const TransactionID = ({ trx_id }: { trx_id: string }) => {
  const url = `https://bloks.io/transaction/${trx_id}`
  const text = trx_id.slice(0, 8) + "..." + trx_id.slice(-8);
  return (
    <Typography style={{fontFamily:"'proxima-soft', sans-serif", textAlign:"center"}}>
      TxID: <Link target="_blank" rel="noopener" href={ url }>{ text }</Link>
    </Typography>
  )
}

const AtomicHubInventory = ({ owner, collection_name, chain }: { owner: string, collection_name: string, chain: string }) => {
  // const navigate = useNavigate();
  const url = `https://${chain}.atomichub.io/profile/${ owner }?collection_name=${collection_name}&order=desc&sort=transferred#inventory`
  return (
    <Button href={ url } target="_blank" variant="contained" style={{ height: "40px", borderRadius:"25px" }} fullWidth color="primary">
      <img height="35px" src={ AtomicHub } />
    </Button>
  )
}

async function retryBlendlog( chain: string, trx_id: string, contract: string ) {
  let retry = 10;
  while ( retry > 0 ) {
    try {
      const response = await getBlendlog( chain, trx_id, contract );
      if ( response ) return response.data;
    } catch (e) {
      console.error("SuccessConfetti::retryBlendlog: retry", { retry, chain, trx_id, contract });
      await timeout(2000);
      retry -= 1;
    }
  }
  console.error("SuccessConfetti::retryBlendlog: no transaction", { chain, trx_id, contract });
  return null;
}

function getLogmintImg( logmint: Logmint ) {
  const orders = ["img", "backimg", "cardimg"];
  const datas = [ logmint.immutable_template_data, logmint.immutable_data, logmint.mutable_data ]

  for ( const order of orders ) {
    for ( const data of datas ) {
      for ( const { key, value } of data ) {
        if ( key === order ) return value[1];
      }
    }
  }
  console.error("SuccessConfetti::getLogmintImg: no image", { logmint });
  return "";
}

const SuccessConfetti = () => {
    const { chain, contract, collection_name } = useParams();
    if ( !chain ) throw new Error("[chain] is required");
    if ( !contract ) throw new Error("[contract] is required");
    if ( !collection_name ) throw new Error("[collection_name] is required");

    const classes = useStyles();
    const [ img, setImg ] = useState( "" );
    const [ visible, setVisible ] = useState( false );
    const [ loading, setLoading ] = useState( false );
    const [ fail, setFail ] = useState( false );
    const [ isConfetti, setIsConfetti ] = useState( false );
    const [isRefreshInventory, setIsRefreshInventory] = useRecoilState(isRefreshInventoryState);
    const [ trx_id, setTrxId ] = useRecoilState( trxIdState );
    const owner = useRecoilValue( ownerState );

    useEffect(() => {
      onLoad();
    }, [ trx_id ]);
    

    const onLoad = async () => {
      console.log("SuccessConfetti::onLoad", {trx_id});
      if ( !trx_id ) return;
      setVisible( true );
      setFail( false );
      setLoading( true );
      const response = await retryBlendlog( chain, trx_id, contract );
      setLoading( false );
      if ( response ) {
        const { logmint } = response;
        setIsConfetti( true );
        setIsRefreshInventory( true );
        if ( logmint ) setImg( getLogmintImg( logmint ) );
      } else {
        setFail( true );
      }
    }

    const handleClose = async () => {
      setVisible(false);
      await timeout(500);
      setLoading(false);
      setIsConfetti(false);
      setTrxId("");
      setImg("")
      setFail(false);
    };

    const confetti = isConfetti ? <Confetti
        numberOfPieces={ 800 }
        run={ isConfetti }
        recycle={ false }
    /> : <></>

    let message = loading ? "🕗 Confirming..." : "Blend Successful 🎉";
    if ( fail ) message = '❌ Not broadcasted';

    return <>
            <Dialog
                open={ visible }
                TransitionComponent={ Transition }
                keepMounted
                onClose={ handleClose }
                aria-labelledby="success-dialog-slide-title"
                aria-describedby="success-dialog-slide-description"
                BackdropProps={{ invisible: false }}
                style={{ zIndex:"1" }}
                className={ classes.dialog }
            >
                { confetti }
                <Typography id="dialog-title" style={{ textAlign:"center", maxWidth:"700px", minWidth:"300px", fontFamily:"'proxima-soft', sans-serif", fontSize:"24px" }}>{ message }</Typography>
                <DialogContent>
                    <TransactionID trx_id={ trx_id } />
                    <div style={{textAlign: "center"}}>
                      { img ? <CardImage img={ img } name={ "image" } /> : loading ? <Loading /> : <></> }
                    </div>
                </DialogContent>
                <DialogActions style={{ padding:"8px" }}>
                    <AtomicHubInventory owner={ owner } collection_name={ collection_name } chain={ chain } />
                    <Button variant="contained" style={{ borderRadius:"25px", height: "40px" }} fullWidth onClick={ handleClose } color="primary">
                        <Close /> Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

}

export default SuccessConfetti;