import { Button } from '@material-ui/core';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ownerState,
  protocolState,
  balanceState,
} from '../atoms/atoms';
import { useLocation } from 'react-router-dom';
import { Transition } from "./Transition";
import { AccountBalanceWallet, Close, ExitToApp } from '@material-ui/icons';
import EOS from '../assets/chains/eos.png';
import WAX from '../assets/chains/wax.png';

import { makeStyles } from '@material-ui/core/styles';
import { wallets } from "../assets/wallets"
import EOSWallet from "eosio-wallets"
import * as storage from "../utils/storage"
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: '25px',
      margin: "8px"
    },
    " & .MuiDialog-paperFullWidth": {
      width: "calc(100% - 24px)"
    }
  },

  button: {
    padding: "8px 8px",
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    }
  },
  dialogTitle: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft:"24px",
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft:"42px",
    }
  },
  dialogCloseButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: "0px 8px 24px 8px",
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: "0px",
      paddingBottom: "24px",
    }
  },
  largeButton: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: "25px", margin: "auto", backgroundColor: "#FFF", padding: "9px 24px", color: "rgb(2 28 77)",
    },
    '&:hover': {
      backgroundColor: "#ffffff9e"
    }
  },
  smallButton: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: "25px", margin: "auto", backgroundColor: "#FFF", padding: "10px 24px", color: "rgb(2 28 77)",
    },
    [theme.breakpoints.up('sm')]: {
      display: "none"
    },
    '&:hover': {
      backgroundColor: "#ffffff9e"
    }
  }
}));

const WalletDialog = ({open, setOpen}: { open: boolean, setOpen: React.Dispatch<any> }) => {
  const classes = useStyles();
  // const [ open, setOpen ] = useRecoilState(walletDialogOpenState);
  const owner = useRecoilValue(ownerState);

  const handleClose = () => {
    setOpen(false);
  };

  return <Dialog
    open={ open }
    TransitionComponent={ Transition }
    keepMounted
    onClose={ handleClose }
    aria-describedby="alert-dialog"
    fullWidth
    className={ classes.dialog }
  >
    { owner ? <WalletAccount handleClose={ handleClose } /> : <SelectWallet handleClose={ handleClose } /> }
  </Dialog>

}

const SelectWallet = ({ handleClose }: { handleClose: () => void } ) => {
  const classes = useStyles();
  console.log("SelectWallet::init", wallets);

  return (
    <>
    <DialogTitle>Connect
      <IconButton
        aria-label="close"
        onClick={ handleClose }
        className={ classes.dialogCloseButton }
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <Grid container style={{ textAlign: "center" }}>
        {wallets.map( (wallet, index) => {
          return <Wallet key={index} name={wallet.name} img={wallet.img} protocol={wallet.protocol} handleClose={ handleClose } />
        })}
      </Grid>
    </DialogContent>
    </>
  )
}

interface WalletProps {
  name: string;
  img: string;
  protocol: string;
  handleClose: () => void;
}

const Wallet = ({name, img, protocol, handleClose }: WalletProps ) => {
  const classes = useStyles();
  const setOwner = useSetRecoilState(ownerState);
  const setProtocol = useSetRecoilState(protocolState);
  const { enqueueSnackbar } = useSnackbar();
  console.log("Wallet::init", { name, img, protocol });

  const onClick = async () => {
    console.log("Wallet::onClick:", { name, img, protocol });

    try {
      const { actor } = await EOSWallet.login(protocol);
      if ( actor ) {
        setOwner( actor );
        setProtocol( protocol );
        storage.set("protocol", protocol );
        storage.set("owner", actor );
        handleClose();
      }

    } catch (err: any) {
      enqueueSnackbar(`Failed connect wallet: ${err.response?.json?.error?.details?.[0]?.message ?? err.message ?? err}`, { variant: 'error' });
      console.error("WalletDialog::Wallet:onClick", err);
    }
  }

  return (
    <Grid item xs={6} sm={3}>
      <Button className={classes.button} onClick={ onClick }>
        <img alt={"logo"} src={ img } style={{ width: "60px", padding: "0px 19px" }} />
        <Typography style={{ fontSize: "0.8rem", paddingTop: "8px" }}>{ name }</Typography>
      </Button>
    </Grid>
  );
}

const WalletAccount = ({ handleClose }: { handleClose: () => void } ) => {
  const classes = useStyles();
  const { pathname } = useLocation()
  let [ chain, contract ] =  pathname.split("/").slice(1);
  if ( !chain ) chain = "eos";
  const [ owner, setOwner ] = useRecoilState(ownerState);
  const [ protocol, setProtocol ] = useRecoilState(protocolState);
  const balance = useRecoilValue( balanceState );

  console.log( "WalletDialog::WalletAccount::init", { pathname, contract, chain, owner, protocol } );

  const disconnect = () => {
    console.log("WalletAccount::disconnect");
    handleClose();
    setProtocol("");
    setOwner("");
    storage.remove("protocol");
    storage.remove("owner");
    EOSWallet.logout();
  }
  const walletIcon = chain === "wax" ? WAX : EOS;

  return (
    <>
    <DialogTitle className={classes.dialogTitle}>Wallet
        <IconButton
          aria-label="close"
          onClick={ handleClose }
          className={ classes.dialogCloseButton }
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container style={{ backgroundColor: "#031c4d", backgroundImage: "linear-gradient(140deg, #0d024d, #542db4)", borderRadius: "15px", padding: "16px" }}>
          <Grid item xs={1} style={{ flexDirection: "row" }}>
            <img alt={"logo"} src={ walletIcon } style={{ height: "40px" }} />
          </Grid>
          <Grid item xs={11} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "auto", paddingRight: "32px" }}>
            <div style={{ paddingLeft: "16px", color: "#FFF" }}>
              <Typography style={{ fontSize: "10px" }}><b>Balance</b></Typography>
              <Typography style={{ fontSize: "16px" }}>{ balance }</Typography>
            </div>
            {/* <div style={{ paddingLeft: "16px", color: "#FFF" }}>
              <Typography style={{ fontSize: "12px" }}>Network</Typography>
              <Typography style={{ fontSize: "16px", textTransform: "uppercase" }}>{ chain }</Typography>
            </div> */}
            <div style={{ paddingLeft: "12px", color: "#FFF" }}>
              <Typography style={{ fontSize: "10px" }}><b>Contract</b></Typography>
              <Typography style={{ fontSize: "16px" }}>{ contract }</Typography>
            </div>
            {/* <div style={{ paddingLeft: "16px", color: "#FFF" }}>
              <Typography style={{ fontSize: "12px" }}>Wallet</Typography>
              <Typography style={{ fontSize: "16px" }}>{ protocol }</Typography>
            </div> */}
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "16px" }}>
          <div style={{ backgroundColor: "#031c4d", backgroundImage: "linear-gradient(140deg, #0d024d, #542db4)", borderRadius: "15px", padding: "16px", width: "100%", display: "flex", flexDirection: "row" }}>
            <AccountBalanceWallet style={{ width: "30px", color: "white", margin: "auto" }} />
            <Typography style={{ color: "#FFF", paddingLeft: "4px", fontSize: "16px", margin: "auto" }}>{ owner }</Typography>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Button><ExitToApp style={{ color: "#FFF" }} />
                <Typography style={{ textTransform: "none", color: "#FFF", fontSize: "16px", paddingLeft: "4px", margin: "auto" }} onClick={ disconnect }>Disconnect</Typography></Button>
            </div>
          </div>
        </Grid>

      </DialogContent>
    </>
  )
}

export default WalletDialog;
