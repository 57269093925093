/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, FC, lazy, Suspense } from 'react';
import { gems } from '../services';
import { Card, Typography, Grid, CardActionArea, Button } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from "../components/Loading";
import { AtomicCollection } from "../types";
import AutoAwesomeLogo from '../assets/auto-awesome.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as ipfs from '../utils/ipfs'
import Slide from '@material-ui/core/Slide';

function toTitleCase( str: string ) {
    return str.split(" ").map( word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ")
}

export const Title = ({name, subName}: {name: string, subName: string}) => {
  return (
    <Typography style={{ padding:"32px 0px 10px 0px", fontWeight: 500, fontSize: "30px", fontFamily: "'proxima-soft', sans-serif" }}>
      <b>{toTitleCase(name)}</b> <i>{subName}</i>
    </Typography>
  )
}

type CollectionCardProps = {
  img: string;
  collection_name: string;
  name: string;
}

const CollectionCard = ({img, name, collection_name}: CollectionCardProps ) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`${collection_name}`);
  }

  return (
    <Slide direction="left" in={true} timeout={450} mountOnEnter unmountOnExit>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={collection_name}>
        <CardActionArea style={{borderRadius:"24px"}} onClick={() => onClick()}>
        <Card style={{padding: "16px", borderRadius:"24px", display:"flex", flexDirection:"column", background:"#4E34DB", border:"5px solid #fff"}}>
          <img
            style={{
              width: "auto",
              height: "300px",
              objectFit: "contain",
              paddingTop: "16px"
            }}
            alt={collection_name}
            onError={(event: any) => event.target.src = ipfs.get_fallback_url(img) }
            src={ ipfs.get_url(img) }
          />
          {/* <Typography style={{ fontSize:"25px", fontWeight: 500, fontFamily: "'proxima-soft', sans-serif", margin:"auto", color:"#FFF" }}>{name}</Typography> */}
          <Button
              variant="contained"
              startIcon={<VisibilityIcon/>}
              style={{ backgroundColor: "#FFF", marginTop: "24px", color: "#4e34db", borderRadius: "25px", padding: "8px", fontFamily:"'proxima-soft', sans-serif", fontWeight:600 }}
              fullWidth
              onClick={onClick}>{name}
          </Button>
        </Card>
        </CardActionArea>
      </Grid>
    </Slide>
  )
}

export default function CollectionPage() {
  const { chain, contract } = useParams();
  if ( !chain) throw new Error("[chain] is required");
  if ( !contract) throw new Error("[contract] is required");
  console.log("CollectionPage::init", { chain, contract });
  const [ atomic_collections, setAtomicCollections ] = useState<AtomicCollection[]>([]);
  const [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    getBlendCollections();
  }, []);

  const getBlendCollections = async () => {
    const response = await gems.getBlendCollections(chain, contract);
    if ( response ) {
      setAtomicCollections(()=> Object.values(response.data.atomic_collections));
      setLoading(false);
    }
  }
  if ( isLoading ) return <Loading />

  return (
    <Slide direction="left" in={true} timeout={250} mountOnEnter unmountOnExit>
      <div style={{
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        flexDirection:"column",
        height: "calc(100vh - 64px)" }}>
            <Title name={ "Blend Collections" } subName={`on ${chain.toUpperCase()}`} />
            <Grid container spacing={2} style={{padding:"16px", display:"flex", justifyContent:"center"}}>
              { atomic_collections.map((atomic_collection) => {
                const { collection_name, serialized_data } = atomic_collection;
                const { name, img, description, url } = serialized_data;

                return <CollectionCard key={collection_name} collection_name={collection_name} img={img} name={name} /> })
              }
            </Grid>
      </div>
    </Slide>
  )
}
